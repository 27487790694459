<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-button
            v-b-modal.modal-modalidad
            variant="primary"
          >
            <span class="text-nowrap">Agregar Modalidad</span>
          </b-button>
          <!-- modal login-->
          <b-modal
            id="modal-modalidad"
            cancel-variant="outline-secondary"
            ok-title="Guardar Modalidad"
            cancel-title="Cancelar"
            centered
            title="Nueva Modalidad"
          >
            <!-- BODY -->
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <!-- Form -->
              <b-form
                class="p-2"
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >

                <!-- Nombre de Curso -->
                <validation-provider
                  #default="validationContext"
                  name="Nombre"
                  rules="required"
                >
                  <b-form-group
                    label="Nombre"
                    label-for="nombre"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-input
                      id="nombre"
                      v-model="modalidadData.nombre"
                      type="text"
                      placeholder="Nombre"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Nombre de Curso -->
                <validation-provider
                  #default="validationContext"
                  name="detalle"
                  rules="required"
                >
                  <b-form-group
                    label="Detalle"
                    label-for="detalle"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-input
                      v-model="modalidadData.detalle"
                      type="text"
                      placeholder="detalle"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Nombre de Curso -->
                <validation-provider
                  #default="validationContext"
                  name="Nombre"
                  rules="required"
                >
                  <b-form-group
                    label="Icono"
                    label-for="icono"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-input
                      id="icono"
                      v-model="modalidadData.icono"
                      type="text"
                      placeholder="Icono"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <input type="submit">
              </b-form>
            </validation-observer>
          </b-modal>
        </b-col>
      </b-row>
    </div>
    <b-table
      responsive="sm"
      :fields="tableColumns"
      :items="modalidades"
    />
  </b-card>
</template>

<script>
import {
  BTable,
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BModal,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'

import ADD_MODALIDAD from '../../../../graphql/cursos/modalidad/AddModalidad.gql'
import GET_MODALIDADES from '../../../../graphql/cursos/modalidad/ListModalidad.gql'

export default {
  components: {
    BTable,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BModal,
    BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankModalidadData = {
      nombre: '',
      detalle: '',
      icono: '',
      estado: '',
    }
    const tableColumns = [
      { key: 'nombre', label: 'Nombre', sortable: true },
      { key: 'detalle', label: 'Detalle', sortable: false },
      { key: 'estado', label: 'Z. Estado', sortable: true },
      { key: 'acciones', label: 'Acción' },
    ]

    const modalidadData = ref(JSON.parse(JSON.stringify(blankModalidadData)))
    const resetmodalidadData = () => {
      modalidadData.value = JSON.parse(JSON.stringify(blankModalidadData))
    }

    // Guardar modalidad
    const { mutate: sendModalidad, onDone, onError } = useMutation(ADD_MODALIDAD, () => ({
      variables: modalidadData.value,
    }))

    // obtener modalidades
    const {
      result,
      loading,
    } = useQuery(GET_MODALIDADES)
    const modalidades = useResult(result, null, data => data.listaModalidad)

    onDone(() => {
      emit('refetch-data')
    })

    onError(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

    const onSubmit = () => {
      sendModalidad()

      emit('refetch-data')
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetmodalidadData)

    return {
      modalidadData,
      onSubmit,
      modalidades,
      loading,
      tableColumns,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
